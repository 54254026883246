import {
  Select,
  LoadingOverlay,
  Tabs,
  Badge,
  Title,
  Button,
  Grid,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { getInvoiceTags, getInvoices } from "../../functions/api/invoiceApi";
import { FormattedMessage } from "react-intl";
import InvoiceList from "../../Components/InvoiceList";
import classes from "./InvoiceOverviewScreen.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { getNotificationData } from "../../functions/api/notificationApi";

function InvoiceOverviewScreen({ type }) {
  const [invoices, setInvoices] = useState([]);
  const [perPage, setPerPage] = useState("40");
  const [isLoading, setIsLoading] = useState(true);
  const [selected, setSelected] = useState([]);
  const [tags, setTags] = useState([]);
  const [filters, setFilters] = useState({
    paymentStatus: [],
    invoiceStatus: [],
    searchTerm: "",
    tags: [],
  });

  const [notif, setNotif] = useState([]);

  useEffect(() => {
    getNotificationData().then((x) => setNotif(x));
  }, []);

  useEffect(() => {
    getInvoiceTags().then((x) =>
      setTags(x.map((tag) => ({ label: tag.tag_name, value: tag.tag_id })))
    );
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getInvoices({ type: type, filters }).then((items) => {
      setInvoices(items);
      setIsLoading(false);
    });
  }, [filters, type]);

  return (
    <div>
      <div style={{ marginLeft: 10, marginRight: 10 }}>
        <Grid style={{ marginTop: 10 }} grow>
          {type != "sent" && (
            <Grid.Col span={{ base: 6, md: 3, lg: 2 }}>
              <Button
                style={{ marginRight: 2 }}
                fullWidth
                onClick={() => (window.location.href = "/sent-invoices")}
                variant="light"
              >
                <FormattedMessage id="sent_invoices" />
              </Button>
            </Grid.Col>
          )}
          {type != "new" && (
            <Grid.Col span={{ base: 6, md: 3, lg: 2 }}>
              <Button
                style={{ marginRight: 2 }}
                fullWidth
                onClick={() => (window.location.href = "/received-invoices")}
                variant="light"
              >
                <FormattedMessage id="new_invoices" />{" "}
                {notif?.invoices > 0 && (
                  <Badge size="xs" style={{ marginLeft: 6 }}>
                    {notif.invoices}
                  </Badge>
                )}
              </Button>
            </Grid.Col>
          )}
          {type && (
            <Grid.Col span={{ base: 6, md: 3, lg: 2 }}>
              <Button
                style={{ marginRight: 2 }}
                fullWidth
                onClick={() => (window.location.href = "all-invoices")}
                variant="light"
              >
                <FormattedMessage id="invoice_overview" />
              </Button>
            </Grid.Col>
          )}
        </Grid>
        <div
          style={{
            marginTop: 10,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Title
            order={2}
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            {!type && <FormattedMessage id="invoice_overview" />}
            {type === "new" && (
              <>
                <FormattedMessage id="new_invoices" />
                {notif?.invoices > 0 && (
                  <span style={{ marginTop: -20 }}>
                    <Badge size="xs" style={{ marginLeft: 6 }}>
                      {notif.invoices}
                    </Badge>
                  </span>
                )}
              </>
            )}
            {type === "sent" && <FormattedMessage id="sent_invoices" />}
          </Title>
          <Select
            style={{
              width: 70,
              float: "right",
              position: "relative",
              marginTop: -10,
              zIndex: 100,
            }}
            value={perPage}
            size="xs"
            onChange={(x) => setPerPage(x)}
            data={[
              { value: "40", label: "40" },
              { value: "80", label: "80" },
              { value: "100", label: "100" },
              { value: "200", label: "200" },
              { value: "500", label: "500" },
            ]}
            label={<FormattedMessage id="records_per_page" />}
          />
        </div>

        {/* <Select
          style={{
            width: 70,
            float: "right",
            position: "relative",
            zIndex: 100,
          }}
          value={perPage}
          size="xs"
          onChange={(x) => setPerPage(x)}
          data={[
            { value: "40", label: "40" },
            { value: "80", label: "80" },
            { value: "100", label: "100" },
            { value: "200", label: "200" },
            { value: "500", label: "500" },
          ]}
          label={<FormattedMessage id="records_per_page" />}
        /> */}
        <div style={{ marginTop: 20, marginBottom: 15 }}>
          <div
            style={{
              position: "relative",
              height: isLoading ? 600 : "auto",
            }}
          >
            <LoadingOverlay
              visible={isLoading}
              overlayProps={{ radius: "sm", blur: 2 }}
              loaderProps={{ color: "pink", type: "bars" }}
            />
            <InvoiceList
              tags={tags}
              invoices={invoices}
              type={type}
              filters={filters}
              setFilters={setFilters}
              setSelected={setSelected}
              selected={selected}
              itemsPerPage={parseInt(perPage, 10)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvoiceOverviewScreen;
