import { Card, Grid } from "@mantine/core";
import { IconArrowBigRightLines } from "@tabler/icons-react";
import { useIntl } from "react-intl";

export const TemplateCard = ({ template, mobile }) => {
  const intl = useIntl();
  return (
    <Grid.Col
      span={{ base: 12 }}
      style={{ paddingTop: 2, paddingBottom: 2 }}
      className={mobile ? "mantine-hidden-from-md" : "mantine-visible-from-md"}
    >
      <Card
        withBorder
        onClick={() => window.open(`/templating/${template.t_uuid}`, "_blank")}
        style={{
          // minHeight: 160,
          background:
            "url(https://images.unsplash.com/photo-1506818144585-74b29c980d4b?q=40&w=300&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
          borderRadius: 10
        }}
      >
        <div
          style={{
            // backgroundColor: "rgba(255, 255, 255, 0.7)",
            backgroundColor: '#00314B90',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            position: "relative",
            zIndex: 1,
            color: "#fcfcfc"
          }}
        >
          <span style={{fontSize: 12}}>{intl.formatMessage({ id: "issue_invoice" })}:</span>
          <IconArrowBigRightLines color="orange" stroke={1.5} />
        </div>
        <strong style={{ position: "relative", zIndex: 1, color: '#FFAA2B' }}>
          {template.template_name}
        </strong>
        <small style={{ position: "relative", zIndex: 1, color: "#fcfcfc" }}>
          {template.template_description}
        </small>
      </Card>
    </Grid.Col>
  );
};
