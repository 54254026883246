import { Button, Card, Title } from "@mantine/core";
import { useIntl } from "react-intl";

export const StartCards = ({
  small,
  background,
  title,
  url,
  spaceBottom,
  description,
}) => {
  const intl = useIntl();

  const style = Object.assign(
    {
      backgroundColor: "#00000050",
      background: background,
      color: "#fff",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      //   borderRadius: 16,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    small
      ? {
          // minHeight: 160,
        }
      : {
          height: "48.5%",
        },
    spaceBottom && {
      marginBottom: "2%",
    }
  );

  if (small) {
    return (
      <Card
        radius="md"
        onClick={() => (window.location.href = url)}
        className={small ? `mantine-hidden-from-md` : `mantine-visible-from-md`}
        style={{ background: "#00314B", color: "#FFAA2B" }}
      >
        <Title order={4} style={{ textAlign: "left" }}>
          {intl.formatMessage({ id: title })}
        </Title>
        {description && <span style={{ color: "#FCFCFC" }}>{description}</span>}
      </Card>
    );
  }

  return (
    <Card
      radius="md"
      onClick={() => (small ? (window.location.href = url) : null)}
      className={small ? `mantine-hidden-from-md` : `mantine-visible-from-md`}
      style={style}
    >
      <Title order={4} style={{ textAlign: "center", marginTop: 20 }}>
        {intl.formatMessage({ id: title })}
      </Title>
      {!small && (
        <Button
          style={{ marginTop: 10 }}
          onClick={() => {
            window.location.href = url;
          }}
        >
          {intl.formatMessage({ id: "go_to" })}
        </Button>
      )}
    </Card>
  );
};
