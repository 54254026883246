import { Button, TextInput } from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";
import { FormattedMessage, useIntl } from "react-intl";

export const ServiceList = ({ services, setServices, individualVat }) => {
  const intl = useIntl();

  const handleAddService = () => {
    setServices([...services, { name: "", cost: 0, quantity: 1, vat: 0 }]);
  };

  const handleCustomServiceNameChange = (event, index) => {
    const newServices = [...services];
    newServices[index]["name"] = event.target.value;
    setServices(newServices);
  };

  const handleRemoveService = (index) => {
    const newServices = [...services];
    newServices.splice(index, 1);
    setServices(newServices);
  };

  const handleServiceChange = (value, index, key) => {
    const newServices = [...services];

    if (key === "cost") {
      if (
        value.length > 1 &&
        value.startsWith("0") &&
        !value.startsWith("0.")
      ) {
        value = value.replace(/^0+/, "");
      }
    }

    if (value === "other") {
      newServices[index][key] = "";
      // newServices[index]["customServiceName"] = true;
    } else {
      newServices[index][key] = value;
      // newServices[index]["customServiceName"] = false;
    }
    setServices(newServices);
  };

  return (
    <div>
      {services.map((service, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: 5,
          }}
        >
          <div style={{ flex: 3 }}>
            <TextInput
              label={
                index === 0 && (
                  <small>{intl.formatMessage({ id: "service_name" })}</small>
                )
              }
              placeholder={intl.formatMessage({ id: "enter_service_name" })}
              value={service.name}
              onChange={(event) => handleCustomServiceNameChange(event, index)}
              style={{ marginBottom: 5 }}
            />
          </div>
          <div style={{ flex: 1 }}>
            <TextInput
              label={
                index === 0 && (
                  <small>
                    {individualVat
                      ? intl.formatMessage({ id: "service_show_full_price" })
                      : intl.formatMessage({ id: "service_price" })}
                  </small>
                )
              }
              placeholder={intl.formatMessage({ id: "cost" })}
              value={service.cost}
              type="number"
              onChange={(event) =>
                handleServiceChange(event.target.value, index, "cost")
              }
              style={{ marginBottom: 5 }}
            />
          </div>
          <div style={{ flex: 1 }}>
            <TextInput
              label={
                index === 0 && (
                  <small>
                    {intl.formatMessage({ id: "service_quantity" })}
                  </small>
                )
              }
              placeholder={intl.formatMessage({ id: "quantity" })}
              value={service.quantity}
              type="number"
              onChange={(event) =>
                handleServiceChange(event.target.value, index, "quantity")
              }
              style={{ marginBottom: 5 }}
            />
          </div>{" "}
          {individualVat && (
            <div style={{ flex: 1 }}>
              <TextInput
                label={
                  index === 0 && (
                    <small>{intl.formatMessage({ id: "vat" })}</small>
                  )
                }
                placeholder={intl.formatMessage({ id: "vat" })}
                value={service?.vat}
                type="number"
                onChange={(event) =>
                  handleServiceChange(event.target.value, index, "vat")
                }
                style={{ marginBottom: 5 }}
              />
            </div>
          )}
          <Button
            variant="outline"
            color="red"
            onClick={() => handleRemoveService(index)}
            style={{
              marginBottom: index === 0 ? 3 : 5,
              marginTop: index === 0 ? 23 : "auto",
            }}
          >
            <IconTrash />
          </Button>
        </div>
      ))}
      <Button
        style={{ marginBottom: 5 }}
        onClick={handleAddService}
        variant="outline"
        color="gray"
        fullWidth
      >
        <FormattedMessage id="add_service" />
      </Button>
    </div>
  );
};
