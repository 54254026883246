import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/tiptap/styles.css";
import MainScreen from "./Screens/MainScreen";
import CreateInvoiceScreen from "./Screens/CreateInvoiceScreen";
import InvoiceOverviewScreen, {
  InvoiceCalendarScreen,
} from "./Screens/InvoiceOverviewScreen";
import ContactScreen from "./Screens/ContactScreen";
import InvoiceScreen from "./Screens/InvoiceScreen";
import SettingsScreen from "./Screens/SettingsScreen";
import { SupportScreen } from "./Screens/SupportScreen/SupportScreen";
import { BusinessSettingsScreen } from "./Screens/SettingsScreen/BusinessSettingsScreen";
import {
  TemplateCreateInvoice,
  TemplateListScreen,
  TemplateScreen,
} from "./Screens/TemplateScreen";
import { EmailInvoiceScreen } from "./Screens/EmailInvoiceScreen";
import { getBusinessID, getToken } from "./functions/tokens";
import DownloadScreen from "./Screens/DownloadScreen/DownloadScreen";
import LoginScreen from "./Screens/LoginScreen";
import { RegisterScreen } from "./Screens/RegisterScreen/RegisterScreen";
import ForgotPassScreen from "./Screens/ForgotPasswordScreen";
import { PassResetScreen } from "./Screens/ForgotPasswordScreen/ForgotPassScreen";
import { ReceiptScreen } from "./Screens/ReceiptScreen";

export const routesLoggedIn = [
  { exct: true, path: "/support", element: <SupportScreen /> },
  { exct: true, path: "/main", element: <MainScreen /> },
  { exct: true, path: "/download/:id", element: <DownloadScreen /> },
  { exct: true, path: "/template-invoices", element: <TemplateListScreen /> },
  { exct: true, path: "/template-invoices/:id", element: <TemplateScreen /> },
  { exct: true, path: "/templating/:id", element: <TemplateCreateInvoice /> },
  { exct: true, path: "/email-invoices", element: <EmailInvoiceScreen /> },
  {
    path: "/create-invoice/:id",
    children: [
      { path: "u/:user", element: <CreateInvoiceScreen /> },
      { path: "i/:invoice", element: <CreateInvoiceScreen /> },
      { path: "e/:invoice", element: <CreateInvoiceScreen /> },
      { path: "", element: <CreateInvoiceScreen /> },
    ],
  },
  {
    exct: true,
    path: "/edit-invoice/:edit",
    element: <CreateInvoiceScreen />,
  },
  { exct: true, path: "/receipts", element: <ReceiptScreen /> },
  { exct: true, path: "/receipts/:id", element: <ReceiptScreen /> },

  { exct: true, path: "/invoice/:id", element: <InvoiceScreen /> },
  { exct: true, path: "/all-invoices", element: <InvoiceOverviewScreen /> },
  {
    exct: true,
    path: "/received-invoices",
    element: <InvoiceOverviewScreen type="new" />,
  },
  {
    exct: true,
    path: "/sent-invoices",
    element: <InvoiceOverviewScreen type="sent" />,
  },
  {
    exct: true,
    path: "/settings",
    element: getBusinessID() ? <BusinessSettingsScreen /> : <SettingsScreen />,
  },
  { exct: true, path: "/invoice-calendar", element: <InvoiceCalendarScreen /> },
  { exct: true, path: "/contacts/", element: <ContactScreen /> },
];

export const routesLoggedOut = [
  { exct: true, path: "/login", element: <LoginScreen /> },
  { exct: true, path: "/register", element: <RegisterScreen /> },
  { exct: true, path: "/forgot-password", element: <ForgotPassScreen /> },
  { exct: true, path: "/reset-password/:token", element: <PassResetScreen /> },
];
