import { useEffect, useMemo, useState } from "react";
import { getReceipts, uploadReceipt } from "../../functions/api/receiptApi";
import {
  Button,
  Card,
  Checkbox,
  FileInput,
  Grid,
  Modal,
  TextInput,
} from "@mantine/core";
import { useIntl } from "react-intl";
import { IconDownload, IconSearch } from "@tabler/icons-react";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { DatePickerInput } from "@mantine/dates";

export const ReceiptScreen = () => {
  const [file, setFile] = useState(null);
  const [modal, setModal] = useState(false);
  const [name, setName] = useState(null);
  const [receipts, setReceipts] = useState([]);
  const [selected, setSelected] = useState(null);
  const [selectedModal, setSelectedModal] = useState(false);
  const [toDownload, setToDownload] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const [filters, setFilters] = useState({
    paymentStatus: [],
    invoiceStatus: [],
    searchTerm: "",
    tags: [],
  });

  const intl = useIntl();

  useEffect(() => {
    fetchReceipts();
  }, []);

  useEffect(() => {
    fetchReceipts();
  }, [filters]);

  const fetchReceipts = () => {
    getReceipts(filters).then((x) => {
      setReceipts(x);
    });
  };

  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  const filteredData = useMemo(
    () =>
      receipts.filter((receipt) =>
        Object.values(receipt).some((value) =>
          value?.toString()?.toLowerCase()?.includes(search.toLowerCase())
        )
      ),
    [receipts, search]
  );

  const uploadReceiptProxy = async () => {
    // setModal(false);
    setLoading(true);
    uploadReceipt(file, name).then((x) => {
      setModal(false);
      setFile(null);
      setName(null);
      setLoading(false);
      fetchReceipts();
    });
  };

  const InfoComponent = ({ receipt }) => {
    const data = JSON.parse(receipt.data);
    return (
      <div style={{ fontSize: 14 }}>
        <div>
          <strong>{intl.formatMessage({ id: "issued_on" })}: </strong>
          {new Date(data.issued_on).toLocaleString()}
        </div>
        <div>
          <Card withBorder style={{ marginTop: 5 }}>
            <strong style={{ borderBottom: "0.5px solid lightgrey" }}>
              {intl.formatMessage({ id: "business" })}
            </strong>
            {data.business && (
              <div style={{ fontSize: 13 }}>
                <div>
                  <strong>{intl.formatMessage({ id: "name" })}:</strong>{" "}
                  {data.business?.name}
                </div>
                <div>
                  <strong>
                    {intl.formatMessage({ id: "business_name" })}:
                  </strong>{" "}
                  {data.business?.company}
                </div>
                <div>
                  <strong>{intl.formatMessage({ id: "reg_id" })}:</strong>{" "}
                  {data.business?.reg_nr}
                </div>
                <div>
                  <strong>{intl.formatMessage({ id: "vat_code" })}:</strong>{" "}
                  {data.business?.tax_code}
                </div>
              </div>
            )}
          </Card>

          <Card withBorder style={{ marginTop: 5 }}>
            <strong style={{ borderBottom: "0.5px solid lightgrey" }}>
              {intl.formatMessage({ id: "services" })}/
              {intl.formatMessage({ id: "items" })}
            </strong>
            {data.items &&
              data.items.map((item) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <strong style={{ maxWidth: "40%" }}>{item.name}</strong>
                  <span>
                    {item.quantity && Number(item.quantity).toFixed(2)}
                  </span>
                  <span>{item.unit_price}</span>
                  <span>
                    {item.total_price} {data.currency}
                  </span>
                </div>
              ))}
          </Card>

          <Card withBorder style={{ marginTop: 5 }}>
            <strong style={{ borderBottom: "0.5px solid lightgrey" }}>
              {intl.formatMessage({ id: "other_data" })}
            </strong>
            {data &&
              Object.keys(data).map((item) => {
                if (
                  item == "business" ||
                  item == "items" ||
                  item == "issued_on"
                ) {
                  return;
                }
                if (typeof data[item] == typeof {}) {
                  return (
                    <>
                      <strong>{item}:</strong>
                      {Object.keys(data[item]).map((it) => {
                        if (typeof data[item][it] != "string") {
                          return;
                        }

                        return (
                          <span style={{ fontSize: 12, marginLeft: 10 }}>
                            <strong>
                              {intl.formatMessage({ id: item })}:{" "}
                            </strong>
                            {data[item][it]}
                          </span>
                        );
                      })}
                    </>
                  );
                }
                return (
                  <div>
                    <strong>{intl.formatMessage({ id: item })}: </strong>
                    <span>{data[item]}</span>
                  </div>
                );
              })}
          </Card>
        </div>
      </div>
    );
  };

  const downloadReceiptsAsZip = async () => {
    const zip = new JSZip();
    const folder = zip.folder("receipts");

    const downloadPromises = toDownload.map(async (receipt) => {
      try {
        const response = await fetch(receipt.url);
        const blob = await response.blob();

        const urlSegments = receipt.url.split("/");
        const fileName = urlSegments[urlSegments.length - 1];

        folder.file(fileName, blob);
      } catch (error) {
        console.error("Error downloading receipt:", error);
      }
    });

    await Promise.all(downloadPromises);

    zip
      .generateAsync({ type: "blob" })
      .then((content) => {
        saveAs(content, "receipts.zip");
      })
      .catch((error) => {
        console.error("Error generating zip file:", error);
      });
  };

  const handleCheckboxChange = (r_id, url) => {
    setToDownload((prevSelected) => {
      const exists = prevSelected.some((item) => item.r_id === r_id);
      if (exists) {
        return prevSelected.filter((item) => item.r_id !== r_id);
      } else {
        return [...prevSelected, { r_id, url }];
      }
    });
  };

  return (
    <div style={{ padding: 10 }}>
      <div style={{ position: "fixed", top: 10, right: 16, zIndex: 5000 }}>
        {toDownload?.length > 0 && (
          <>
            <Button onClick={downloadReceiptsAsZip} hiddenFrom="sm">
              <IconDownload size={14} />
            </Button>
            <Button
              onClick={downloadReceiptsAsZip}
              visibleFrom="sm"
              rightSection={<IconDownload size={14} />}
            >
              {intl.formatMessage(
                { id: "download_receipts" },
                { count: toDownload?.length }
              )}
            </Button>
          </>
        )}
      </div>
      <Modal
        opened={selectedModal}
        onClose={() => setSelectedModal(false)}
        size="xl"
      >
        <Grid>
          {selected && selected.url && (
            <Grid.Col span={{ base: 12, md: 6 }}>
              <div style={{ fontSize: 14 }}>
                <strong>{intl.formatMessage({ id: "created_on" })}: </strong>
                {new Date(selected.created).toLocaleString()}
              </div>
              <strong>{selected.receipt_name && selected.receipt_name}</strong>
              <img style={{ maxWidth: "100%" }} src={selected.url} />
              <Button
                onClick={() => window.open(selected.url, "_blank")}
                fullWidth
              >
                {intl.formatMessage({ id: "download" })}
              </Button>
            </Grid.Col>
          )}
          <Grid.Col span={{ base: 12, md: 6 }}>
            <div
              style={{
                maxWidth: "100%",
                overflow: "auto",
                wordBreak: "break-word",
              }}
            >
              {selected && selected.data && (
                <InfoComponent receipt={selected} />
              )}
            </div>
          </Grid.Col>
        </Grid>
      </Modal>
      <Modal
        opened={modal}
        onClose={() => setModal(false)}
        title={intl.formatMessage({ id: "add_receipt" })}
      >
        <FileInput
          clearable
          disabled={loading}
          value={file}
          onChange={setFile}
          label={intl.formatMessage({ id: "select_receipt_file" })}
          placeholder={intl.formatMessage({ id: "select_receipt_file" })}
        />
        {file && (
          <TextInput
            disabled={loading}
            label={intl.formatMessage({ id: "receipt_name" })}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        )}
        {file && (
          <Button
            onClick={() => uploadReceiptProxy()}
            loading={loading}
            fullWidth
            style={{ marginTop: 15 }}
          >
            {intl.formatMessage({ id: "add_receipt" })}
          </Button>
        )}
      </Modal>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h3>{intl.formatMessage({ id: "receipts" })}</h3>
        <Button size="xs" onClick={() => setModal(true)}>
          {intl.formatMessage({ id: "add_receipt" })}
        </Button>
      </div>
      <Grid>
        <Grid.Col span={{ base: 12, md: 6 }} style={{ marginTop: -15 }}>
          <TextInput
            label={" "}
            size="xs"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            rightSection={<IconSearch />}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6 }} style={{ marginTop: -15 }}>
          <DatePickerInput
            valueFormat="DD/MM/YYYY"
            type="range"
            size="xs"
            allowSingleDateInRange
            label={intl.formatMessage({ id: "created_on" })}
            placeholder={intl.formatMessage({ id: "created_on" })}
            value={filters.createdOn}
            onChange={(value) => handleFilterChange("createdOn", value)}
            clearable
          />
        </Grid.Col>
      </Grid>
      <hr />
      <Grid>
        {filteredData &&
          filteredData.map(
            (receipt) =>
              receipt.url && (
                <Grid.Col span={{ base: 6, md: 4, lg: 2 }}>
                  <Card withBorder>
                    <div
                      style={{ cursor: "pointer", maxWidth: "100%" }}
                      onClick={() => {
                        setSelectedModal(true);
                        setSelected(receipt);
                      }}
                    >
                      <div style={{}}>
                        {receipt.url && (
                          <img style={{ width: "100%" }} src={receipt.url} />
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span
                        style={{ minHeight: 25 }}
                        onClick={() => {
                          setSelectedModal(true);
                          setSelected(receipt);
                        }}
                      >
                        {receipt.receipt_name ? receipt.receipt_name : ""}
                      </span>
                      {/* <Checkbox
                        checked={toDownload.some(
                          (item) => item.r_id === receipt.r_id
                        )}
                        onChange={() =>
                          handleCheckboxChange(receipt.r_id, receipt.url)
                        }
                        size="xs"
                      /> */}
                    </div>
                  </Card>
                </Grid.Col>
              )
          )}
      </Grid>
    </div>
  );
};
