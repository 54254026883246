import {
  Button,
  Badge,
  Card,
  Modal,
  Select,
  SimpleGrid,
  TextInput,
  Alert,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  getInvoiceNames,
  saveInvoiceName,
} from "../../functions/api/invoiceApi";
import { X } from "tabler-icons-react";

export function replacePlaceholders(str, start) {
  const currentDate = new Date();
  const year = currentDate.getFullYear().toString();
  let month = (currentDate.getMonth() + 1).toString();
  let day = currentDate.getDate().toString();

  const replacements = {
    m: month,
    "0m": month.padStart(2, "0"),
    d: day,
    "0d": day.padStart(2, "0"),
    y: year.substring(2),
    Y: year,
    nr: start,
  };

  // Extract all %txt{{...}}% placeholders
  const txtRegex = /%txt\{\{(.*?)\}\}%/g;
  let match;
  while ((match = txtRegex.exec(str)) !== null) {
    replacements[`txt{{${match[1]}}}`] = match[1];
  }

  // Perform all replacements
  for (const [placeholder, value] of Object.entries(replacements)) {
    const regex = new RegExp(`%${placeholder}%`, "g");
    str = str?.replace(regex, value);
  }

  return str;
}

export const EditInvoiceNrModal = ({ open, setOpen, setInvoiceNr }) => {
  const intl = useIntl();
  const [type, setType] = useState("custom");
  const [value, setValue] = useState();
  const [start, setStart] = useState(1);
  const [nrs, setNrs] = useState();
  const [presets, setPresets] = useState([]);
  const [placeholders, setPlaceholders] = useState([]);
  const [tmpText, setTmpText] = useState("");

  const removePlaceholder = (index) => {
    setPlaceholders((prevPlaceholders) =>
      prevPlaceholders.filter((_, i) => i !== index)
    );
  };

  const addPlaceholder = (placeholder) => {
    setPlaceholders((prevPlaceholders) => [...prevPlaceholders, placeholder]);
  };

  useEffect(() => {
    const initNrs = [
      { label: intl.formatMessage({ id: "inv_custom" }), value: "custom" },
      { label: intl.formatMessage({ id: "inv_free" }), value: "free" },
      {
        label: intl.formatMessage({ id: "inv_index_month" }),
        value: "index_month",
      },
      {
        label: intl.formatMessage({ id: "inv_month_index" }),
        value: "month_index",
      },
    ];
    getInvoiceNames().then((x) => {
      setPresets(x);
      setNrs([...initNrs, ...x]);
    });
  }, [open]);

  useEffect(() => {
    setPlaceholders("");
    setStart(1);
    if (
      type != "custom" &&
      type != "free" &&
      type != "index_month" &&
      type != "month_index"
    ) {
      const picked = presets.find((el) => el.value == type);
      setPlaceholders(picked?.label?.split(/(%\w+%|[_\/\-])/).filter(Boolean));
      setStart(picked?.invoice_index);
    }
    if (type == "index_month" || type == "month_index") {
      const data = type == "index_month" ? "%nr%_%0m%_%Y%" : "%Y%_%0m%_%nr%";
      setPlaceholders(data.split(/(%\w+%|[_\/\-])/).filter(Boolean));
    }
  }, [type]);

  const saveInvoiceSelection = async () => {
    if (type == "free") {
      setInvoiceNr({ value: value, id: 0 });
    }
    if (type != "free") {
      const result = await saveInvoiceName({
        invoice_name: placeholders.join(""),
        invoice_index: start,
        indexid: (type != "custom" && type != "month_index" && type != "index_month") ? type : null,
      });

      setInvoiceNr({
        value: replacePlaceholders(
          placeholders ? placeholders.join("") : "",
          start
        ),
        id: result.id,
      });
    }
    setOpen(false);
  };

  const transformedNrs = nrs?.map((nr) => {
    let transformedLabel;

    if (nr.value === "free" || nr.value === "custom") {
      transformedLabel = nr.label;
    } else {
      // Split the label by placeholders and other delimiters
      transformedLabel = nr.label
        .split(/(%\w+%|%txt\{\{.*?\}\}%|[_\/\-])/)
        .filter(Boolean)
        .map((a) => {
          // Check if the substring is a simple placeholder (e.g., %Y%) and needs formatting
          if (/%\w+%/.test(a)) {
            return intl.formatMessage({
              id: `invoice_nr_explain_simple_${a.replace(/%/g, "")}`,
            });
          }

          // Check if the substring is a %txt{{...}}% placeholder and needs formatting
          const txtMatch = a.match(/%txt\{\{(.*?)\}\}%/);
          if (txtMatch) {
            return txtMatch[1];
          }

          return a;
        })
        .join(""); // Join back to a single string
    }

    return {
      ...nr,
      label: transformedLabel,
    };
  });

  const formatLabelText = (text) => {
    setTmpText(`%txt{{${text}}}%`);
  };

  const getformatValueText = (text) => {
    const match = text.match(/%txt\{\{(.*?)\}\}%/);
    return match ? match[1] : null;
  };

  const textToPh = () => {
    if (tmpText && tmpText.trim() != "") {
      addPlaceholder(`%txt{{${tmpText}}}%`);
      setTmpText("");
    }
  };

  return (
    <Modal
      opened={open}
      onClose={() => setOpen(false)}
      title={intl.formatMessage({ id: "invoice_name" })}
    >
      <Select
        label={intl.formatMessage({ id: "select_invoice_name_type" })}
        data={transformedNrs}
        value={type}
        onChange={setType}
        searchable
      />
      {type != "free" && (
        <div>
          <Alert style={{ marginTop: 10, marginBottom: 5, padding: 10 }}>
            <span style={{ fontSize: 11 }}>
              {intl.formatMessage({
                id: "invoice_nr_placeholder_text_explain",
              })}
            </span>
          </Alert>
          <SimpleGrid
            spacing={{ base: 5 }}
            cols={2}
            style={{ marginTop: 15, marginBottom: 15 }}
          >
            <Button
              style={{ fontSize: 13 }}
              onClick={() => addPlaceholder("%m%")}
            >
              {intl.formatMessage({ id: "invoice_nr_explain_m" })}
            </Button>
            <Button
              style={{ fontSize: 13 }}
              onClick={() => addPlaceholder("%0m%")}
            >
              {intl.formatMessage({ id: "invoice_nr_explain_0m" })}
            </Button>
            <Button
              style={{ fontSize: 13 }}
              onClick={() => addPlaceholder("%d%")}
            >
              {intl.formatMessage({ id: "invoice_nr_explain_d" })}
            </Button>
            <Button
              style={{ fontSize: 13 }}
              onClick={() => addPlaceholder("%0d%")}
            >
              {intl.formatMessage({ id: "invoice_nr_explain_0d" })}
            </Button>
            <Button
              style={{ fontSize: 13 }}
              onClick={() => addPlaceholder("%y%")}
            >
              {intl.formatMessage({ id: "invoice_nr_explain_y" })}
            </Button>
            <Button
              style={{ fontSize: 13 }}
              onClick={() => addPlaceholder("%Y%")}
            >
              {intl.formatMessage({ id: "invoice_nr_explain_Y" })}
            </Button>
          </SimpleGrid>
          <SimpleGrid cols={4}>
            <Button onClick={() => addPlaceholder("%nr%")}>NR</Button>
            <Button onClick={() => addPlaceholder("-")}>-</Button>
            <Button onClick={() => addPlaceholder("_")}>_</Button>
            <Button onClick={() => addPlaceholder("/")}>/</Button>
          </SimpleGrid>
          <TextInput
            label={intl.formatMessage({ id: "invoice_nr_text" })}
            onChange={(e) => setTmpText(e.target.value)}
            style={{ marginTop: 5, marginBottom: 15 }}
            value={tmpText}
            rightSection={
              <Button onClick={textToPh}>
                {intl.formatMessage({ id: "invoice_nr_insert" })}
              </Button>
            }
            rightSectionWidth={"auto"}
          />
          <div cols={2} spacing={{ base: 5 }} style={{ marginTop: 10 }}>
            {placeholders &&
              placeholders?.map((placeholder, index) => (
                <Badge
                  color="green"
                  size="md"
                  style={{ margin: 2 }}
                  key={index}
                  leftSection={
                    <X onClick={() => removePlaceholder(index)}>X</X>
                  }
                >
                  {getformatValueText(placeholder)
                    ? getformatValueText(placeholder)
                    : intl.formatMessage({
                        id: `invoice_nr_explain_${placeholder.replace(
                          /%/g,
                          ""
                        )}`,
                      })}
                </Badge>
              ))}
          </div>
          {placeholders && placeholders.includes("%nr%") && (
            <TextInput
              type="number"
              label={intl.formatMessage({ id: "inv_start_nr" })}
              value={start}
              onChange={(t) => setStart(t.target.value)}
            />
          )}
          {placeholders && (
            <Card withBorder mt="10" p="10" style={{ fontSize: 12 }}>
              <strong>{intl.formatMessage({ id: "inv_name_example" })}</strong>
              {replacePlaceholders(placeholders.join(""), start)}
            </Card>
          )}
        </div>
      )}
      {type == "free" && (
        <TextInput
          value={value}
          onChange={(t) => setValue(t.target.value)}
          label={intl.formatMessage({ id: "inv_name" })}
        />
      )}
      <div style={{ marginTop: 20 }}>
        <Button onClick={saveInvoiceSelection} fullWidth>
          {intl.formatMessage({ id: "save" })}
        </Button>
      </div>
    </Modal>
  );
};
