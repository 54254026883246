import {
  AppShell,
  Burger,
  Button,
  Card,
  Group,
  Menu,
  ScrollArea,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { TextInput, UnstyledButton, Badge, rem } from "@mantine/core";
import {
  IconUser,
  IconTransferOut,
  IconReportMoney,
  IconSearch,
  IconFileStack,
  IconNewSection,
  IconCalendar,
  IconSettings,
  IconLogout,
  IconQuestionMark,
  IconArrowBigRightLines,
  IconMailFast,
  IconReceipt
} from "@tabler/icons-react";
import classes from "./MainContainer.module.css";
import { Logo } from "../Logo/Logo";
import { useEffect, useState } from "react";
import { getInvoices } from "../../functions/api/invoiceApi";
import { getNotificationData } from "../../functions/api/notificationApi";
import { useIntl } from "react-intl";
import { getAllUserBusinesses } from "../../functions/api/businessApi";
import { CombinedUserButton } from "../UserButton/AccountSelector";
import { handleTheLanguageSwitch } from "../../Screens/SettingsScreen/fn";

export function MainContainer({ children }) {
  const intl = useIntl();

  const [opened, { toggle }] = useDisclosure();
  const [search, setSearch] = useState(null);
  const [results, setResults] = useState(null);
  const [notif, setNotif] = useState([]);
  const [biz, setBiz] = useState([]);

  useEffect(() => {
    if (search?.length > 3) {
      getInvoices({ search: search }).then((x) => setResults(x));
    } else {
      setResults(null);
    }
  }, [search]);

  useEffect(() => {
    getAllUserBusinesses().then((x) => {
      setBiz(x?.results);
    });

    getNotificationData().then((x) => {
      setNotif(x);
    });
  }, []);

  const links = [
    {
      icon: IconNewSection,
      label: intl.formatMessage({ id: "issue_invoice" }),
      href: "/create-invoice/new",
    },
    {
      icon: IconMailFast,
      iconClass: classes.colorChangingItem,
      label: (
        <span className={classes.colorChangingItem}>
          {intl.formatMessage({ id: "email_invoices" })}
        </span>
      ),
      href: "/email-invoices",
      notifications: notif?.emails,
    },
    {
      icon: IconArrowBigRightLines,
      label: (
        <span style={{ color: "green" }}>
          {intl.formatMessage({ id: "template_invoices" })}
        </span>
      ),
      href: "/template-invoices",
    },
    {
      icon: IconFileStack,
      label: intl.formatMessage({ id: "invoices" }),
      notifications: notif?.invoices,
      href: notif?.invoices > 0 ? "/received-invoices" : "/all-invoices",
    },
    {
      icon: IconReceipt,
      label: intl.formatMessage({ id: "receipts" }),
      href: "/receipts",
    },
    // {
    //   icon: IconFileStack,
    //   label: intl.formatMessage({ id: "all_invoices" }),
    //   notificationsx: 4,
    //   href: "/all-invoices",
    // },
    // {
    //   icon: IconReportMoney,
    //   label: intl.formatMessage({ id: "received_invoices" }),
    //   notifications: notif?.invoices,
    //   href: "/received-invoices",
    // },
    // {
    //   icon: IconTransferOut,
    //   label: intl.formatMessage({ id: "sent_invoices" }),
    //   href: "/sent-invoices",
    // },
    {
      icon: IconCalendar,
      label: intl.formatMessage({ id: "invoice_calendar" }),
      notificationsx: 3,
      href: "/invoice-calendar",
    },
    {
      icon: IconUser,
      label: intl.formatMessage({ id: "contacts" }),
      href: "/contacts",
      notifications: notif?.friends,
    },
    {
      icon: IconQuestionMark,
      label: intl.formatMessage({ id: "support_main" }),
      href: "/support",
    },
    {
      icon: IconSettings,
      label: intl.formatMessage({ id: "profile_settings" }),
      href: "/settings",
    },
  ].filter(Boolean);

  const mainLinks = links.map((link, index) => (
    <UnstyledButton
      key={`m-l-m-c-${index}`}
      className={classes.mainLink}
      onClick={() => link.href && (window.location.href = link.href)}
    >
      <div className={classes.mainLinkInner}>
        <link.icon
          size={20}
          className={`${classes.mainLinkIcon} ${
            link.iconClass ? link.iconClass : ""
          }`}
          stroke={1.5}
        />
        <span>{link.label}</span>
      </div>
      {link.notifications && link.notifications > 0 && (
        <Badge size="sm" variant="filled" className={classes.mainLinkBadge}>
          {link.notifications}
        </Badge>
      )}
    </UnstyledButton>
  ));

  return (
    <AppShell
      header={{ height: 60 }}
      navbar={{ width: 300, breakpoint: "sm", collapsed: { mobile: !opened } }}
      padding=""
    >
      <AppShell.Header>
        <Group h="100%" px="md">
          <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
          <Logo />
        </Group>
      </AppShell.Header>
      <AppShell.Navbar p="md">
        <div className={classes.section}>
          <CombinedUserButton data={biz} />
        </div>

        <TextInput
          placeholder={intl.formatMessage({ id: "search_for_invoice" })}
          size="xs"
          leftSection={
            <IconSearch
              style={{ width: rem(12), height: rem(12) }}
              stroke={1.5}
            />
          }
          value={search}
          onChange={(x) => setSearch(x.target.value)}
          rightSectionWidth={30}
          // rightSection={
          //   <IconX
          //     onClick={() => setSearch(null)}
          //     style={{ width: rem(18), height: rem(18) }}
          //     stroke={1}
          //   />
          // }
          styles={{ section: { pointerEvents: "none" } }}
          mb="sm"
        />

        <div className={classes.section}>
          <div className={classes.mainLinks}>
            {results ? (
              <ScrollArea.Autosize
                h={500}
                scrollbars="y"
                type="auto"
                scrollbarSize={4}
              >
                {results.length > 0 ? (
                  results.map((x, index) => (
                    <a
                      key={`${index}-s-r`}
                      href={`/invoice/${x.encrypted}`}
                      style={{ textDecoration: "none" }}
                    >
                      <Card withBorder style={{ marginTop: 5 }}>
                        <div style={{ color: "lightgrey", fontSize: 8 }}>
                          {x.encrypted}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <small>{x.invoice_name}</small>
                          <small>{x.total_vat}€</small>
                        </div>
                      </Card>
                    </a>
                  ))
                ) : (
                  <div style={{ padding: 10 }}>
                    {intl.formatMessage({ id: "invoices_not_found" })}
                  </div>
                )}
              </ScrollArea.Autosize>
            ) : (
              <>
                {mainLinks}
                <UnstyledButton
                  style={{ bottom: 10, position: "absolute", width: "70%" }}
                  className={classes.mainLink}
                  onClick={() => (window.location.href = "/logout")}
                >
                  <div className={classes.mainLinkInner}>
                    <IconLogout
                      size={20}
                      className={classes.mainLinkIcon}
                      stroke={1.5}
                    />
                    <span>Logout</span>
                  </div>
                </UnstyledButton>
                <Menu shadow="md">
                  <Menu.Target>
                    <UnstyledButton
                      className={classes.mainLink}
                      style={{
                        width: "auto",
                        bottom: 10,
                        position: "absolute",
                        right: 0,
                        paddingTop: 1,
                        paddingBottom: 1,
                        paddingLeft: 10,
                        paddingRight: 10,
                        fontSize: 22,
                      }}
                    >
                      {intl.locale == "en-GB" ? "🇺🇸" : "🇱🇻"}
                    </UnstyledButton>
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Label>
                      {intl.formatMessage({ id: "language" })}
                    </Menu.Label>
                    <Menu.Item onClick={() => handleTheLanguageSwitch("lv-LV")}>
                      {intl.formatMessage({ id: "lv_lng" })}
                    </Menu.Item>
                    <Menu.Item onClick={() => handleTheLanguageSwitch("en-GB")}>
                      {intl.formatMessage({ id: "en_lng" })}
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </>
            )}
          </div>
        </div>
      </AppShell.Navbar>
      <AppShell.Main>
        <div style={{ padding: 5, maxWidth: 1500 }}>{children}</div>
      </AppShell.Main>
      <div style={{ marginBottom: 150 }}></div>
    </AppShell>
  );
}
