import {
  Button,
  Card,
  FileButton,
  FileInput,
  Grid,
  Image,
  Menu,
  Modal,
  NumberInput,
  Select,
  TagsInput,
  Text,
  TextInput,
  UnstyledButton,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Edit, Stars, X } from "tabler-icons-react";
import {
  getInvoiceLogos,
  getInvoiceTags,
  uploadLogoAPI,
} from "../../functions/api/invoiceApi";
import { dataFromInvoiceAI } from "../../functions/api/credentialsApi";

export const InvoiceLanguageContainer = ({ lng, setLng }) => {
  const intl = useIntl();

  return (
    <Select
      size="xs"
      value={lng}
      onChange={(e) => setLng(e)}
      style={{ marginTop: -6 }}
      label={intl.formatMessage({ id: "invoice_language" })}
      data={[
        { value: "lv-LV", label: `${intl.formatMessage({ id: "lv_lng" })}` },
        { value: "en-GB", label: `${intl.formatMessage({ id: "en_lng" })}` },
      ]}
    />
  );
};

export const LogoContainer = ({ logo, setLogo }) => {
  const [loading, setLoading] = useState(false);
  const [logos, setLogos] = useState([]);
  const [show, setShow] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    if (show) {
      setLoading(true);
      getInvoiceLogos().then((x) => {
        const list = x.map((item) => item.logo_url);
        setLogos(list);
        setLoading(false);
      });
    }
  }, [show]);

  const uploadLogoAPIProxy = (file) => {
    uploadLogoAPI(file).then((x) => setLogos((prev) => [...prev, x.logo_url]));
  };
  return (
    <div>
      <Modal
        opened={show}
        title={intl.formatMessage({ id: "set_logo" })}
        onClose={() => setShow(false)}
      >
        <FileButton onChange={uploadLogoAPIProxy}>
          {(props) => (
            <Button {...props}>
              {intl.formatMessage({ id: "upload_new_logo" })}
            </Button>
          )}
        </FileButton>
        {logos && (
          <div style={{ marginTop: 20, fontWeight: 500 }}>
            {intl.formatMessage({ id: "select_existing_logo" })}
          </div>
        )}
        <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
          <Grid>
            {logos &&
              logos.map((lx) => (
                <Grid.Col span={{ base: 4 }}>
                  <Card
                    onClick={() => setLogo(lx)}
                    withBorder={lx == logo}
                    style={{
                      flexDirection: "unset",
                      height: 100,
                      backgroundColor: lx == logo ? "#00ff0005" : "",
                      borderColor: "green",
                    }}
                  >
                    <Image radius="md" fit="contain" src={lx} />
                  </Card>
                </Grid.Col>
              ))}
          </Grid>
        </div>
      </Modal>
      <Card withBorder>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            {logo ? (
              <Image
                style={{ marginTop: -15, marginBottom: -15, marginLeft: -10 }}
                h={80}
                radius="md"
                fit="contain"
                src={logo}
              />
            ) : (
              <>
                <div>
                  <small style={{ fontWeight: 500, fontSize: 14 }}>
                    {intl.formatMessage({ id: "logo" })}
                  </small>
                </div>
                <span style={{ fontSize: 12, color: "gray" }}>
                  {intl.formatMessage({ id: "no_logo_set" })}
                </span>
              </>
            )}
          </div>
          <span>
            {logo && (
              <Button
                color="red"
                onClick={() => setLogo(null)}
                style={{ marginRight: 5 }}
              >
                <X size={18} />
              </Button>
            )}
            <Button onClick={() => setShow(true)}>
              <Edit size={18} />
            </Button>
          </span>
        </div>
      </Card>
    </div>
  );
};

export const InvoiceNameContainer = ({ setInvoiceModal, invoiceName }) => {
  const intl = useIntl();

  return (
    <Card withBorder>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <div>
            <small style={{ fontWeight: 500, fontSize: 14 }}>
              {intl.formatMessage({ id: "invoice_name" })}
            </small>
          </div>
          <span style={{ fontSize: 14 }}>{invoiceName?.value}</span>
        </div>
        <span>
          <Button onClick={() => setInvoiceModal(true)}>
            <Edit size={18} />
          </Button>
        </span>
      </div>
    </Card>
  );
};

export const DateSelectionFields = ({
  issueDate,
  handleIssueDateChange,
  dueDate,
  handleDueDateChange,
}) => {
  const intl = useIntl();

  return (
    <Grid>
      <Grid.Col span={{ base: 12, md: 6 }}>
        <DateInput
          label={intl.formatMessage({ id: "issued_on" })}
          value={issueDate}
          onChange={handleIssueDateChange}
          style={{ marginBottom: 0 }}
        />
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 6 }}>
        <DateInput
          label={intl.formatMessage({ id: "due_date" })}
          value={dueDate}
          onChange={handleDueDateChange}
          style={{ marginBottom: 15 }}
        />
      </Grid.Col>
    </Grid>
  );
};

export const DiscountInput = ({ discount, setDiscount }) => {
  const intl = useIntl();

  return (
    <NumberInput
      placeholder={`${intl.formatMessage({ id: "discount" })} %`}
      value={discount}
      type="number"
      rightSection={"%"}
      decimalScale={2}
      decimalSeparator="."
      min={0}
      max={100}
      label={`${intl.formatMessage({ id: "discount" })} %`}
      onChange={(value) => setDiscount(value)}
      style={{ marginBottom: 5 }}
    />
  );
};

export const TaxInput = ({ taxRate, setTaxRate }) => {
  const intl = useIntl();

  return (
    <TextInput
      label={intl.formatMessage({ id: "tax_rate" })}
      placeholder={intl.formatMessage({ id: "tax_rate" })}
      value={taxRate > 0 ? taxRate : 0}
      type="number"
      onChange={(event) => setTaxRate(event.target.value)}
      style={{ marginBottom: 5 }}
    />
  );
};

export const TotalsHolder = ({
  individualVat,
  individualSubTotalNoVat,
  subtotal,
  taxRate,
  discount,
  amountDue,
}) => {
  const intl = useIntl();

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: 5,
        }}
      >
        <Text
          style={{
            fontWeight: 600,
            marginRight: 5,
            width: 160,
          }}
        >
          {intl.formatMessage({ id: "subtotal" })}
        </Text>
        <Text>
          €
          {Number(individualVat ? individualSubTotalNoVat : subtotal).toFixed(
            2
          )}
        </Text>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: 5,
          color: "gray",
        }}
      >
        {(individualVat || (taxRate && taxRate > 0)) && (
          <>
            <Text
              style={{
                fontWeight: 600,
                marginRight: 5,
                fontSize: 13,
                width: 160,
              }}
            >
              {!individualVat && `${taxRate}%`}{" "}
              {intl.formatMessage({ id: "tax" })}
            </Text>
            <Text style={{ color: "gray", fontSize: 13 }}>
              €
              {Number(
                individualVat
                  ? subtotal - individualSubTotalNoVat
                  : subtotal * (taxRate / 100) || 0
              ).toFixed(2)}
            </Text>
          </>
        )}
      </div>
      {discount > 0 && (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: 5,
            }}
          >
            <Text
              style={{
                fontWeight: 600,
                marginRight: 5,
                width: 160,
              }}
            >
              {intl.formatMessage({ id: "subtotal_no_disc" })}:
            </Text>
            <Text>€{Number(amountDue).toFixed(2)}</Text>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: 5,
            }}
          >
            <Text
              style={{
                fontWeight: 600,
                marginRight: 5,
                width: 160,
              }}
            >
              {intl.formatMessage({ id: "discount" })}:
            </Text>
            <Text>€{(Number(amountDue) * (discount / 100)).toFixed(2)}</Text>
          </div>
        </>
      )}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: 5,
        }}
      >
        <Text
          style={{
            fontWeight: 600,
            marginRight: 5,
            width: 160,
          }}
        >
          {intl.formatMessage({ id: "total_due" })}
        </Text>
        <Text>
          €
          {discount && discount != 0
            ? (Number(amountDue) * (1 - discount / 100)).toFixed(2)
            : Number(amountDue).toFixed(2)}
        </Text>
      </div>
    </div>
  );
};

export const InvoiceTagInput = ({ tags, setTags }) => {
  const intl = useIntl();

  const [selectedTags, setSelectedTags] = useState(tags ? tags : []);

  const [pTags, setPtags] = useState([]);

  useEffect(() => {
    getInvoiceTags().then((x) => {
      setPtags(x);
    });
  }, []);

  useEffect(() => {
    setSelectedTags(tags);
  }, [tags]);

  const handleTagChange = (newTags) => {
    const updatedTags = newTags.map((tag) => {
      const existingTag = pTags.find((ptag) => ptag.tag_name === tag);

      if (existingTag) {
        return existingTag;
      } else {
        return { id: null, tag_name: tag };
      }
    });

    setSelectedTags(updatedTags);
    setTags(updatedTags);
  };

  return (
    <TagsInput
      label={intl.formatMessage({ id: "add_tags_to_invoice" })}
      description={intl.formatMessage({
        id: "add_tags_to_invoice_description",
      })}
      value={selectedTags.map((tag) => tag.tag_name)} // Map selected tags to their names for display
      onChange={handleTagChange}
      data={pTags?.map((tag) => tag.tag_name)} // Use existing tag names for suggestions
      limit={20}
      splitChars={[",", "."]}
    />
  );
};

export const ImportDataAIModal = ({ action, open, setOpen }) => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const intl = useIntl();

  const process = async () => {
    if (file) {
      setLoading(true);
      const res = await dataFromInvoiceAI(file);
      action && action(res);
      setLoading(false);
      setOpen(false);
    }
  };

  return (
    <Modal
      opened={open}
      onClose={() => setOpen(false)}
      title={intl.formatMessage({ id: "import_data_from_invoice" })}
    >
      <FileInput
        clearable
        value={file}
        onChange={setFile}
        label={intl.formatMessage({ id: "select_invoice" })}
        placeholder={intl.formatMessage({ id: "select_invoice" })}
      />
      <Button
        loading={loading}
        fullWidth
        style={{ marginTop: 15 }}
        onClick={process}
        disabled={!file}
      >
        {intl.formatMessage({ id: "process_invoice" })}{" "}
        <Stars
          style={{ rotate: "50deg", marginBottom: 3, marginLeft: 3 }}
          fill="yellow"
          color="orange"
          size={14}
          strokeWidth={1}
        />
      </Button>
    </Modal>
  );
};
