import { apiFetch, handleResponse, url } from ".";
import { getBusinessID, getToken } from "../tokens";

const r = "receipts/";

export const uploadReceipt = (file, name) => {
  const token = getToken();
  const formData = new FormData();
  const business_id = getBusinessID();
  formData.append("file", file);
  formData.append("name", name);

  return fetch(url + "upgo-route/upload-receipt", {
    method: "POST",
    headers: {
      Authorization: `${token}`,
      Business: `${business_id}`,
    },
    body: formData,
  }).then((response) => handleResponse(response));
};

export const getReceipts = (data) => apiFetch(`${r}get-all`, data);
